.right-arrow {
  font-size: 3rem;
  color: #000;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  font-size: 3rem;
  color: #000;
  cursor: pointer;
  user-select: none;
}
